import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../../EnvConfig/env_config';
import { DropDownData, FetchAutoCompleteData, getCommonData, handleDownloadExcel } from "../Common/CommanServices";
import { ProductHeader, dateFormateWithTime, number, numberwithDot, setFocus } from '../Common/Common';
import { AlertBox, AutoCompleteBox, DateBox, InputBox, MultiSelectInputBox, MultiValueSelectBox, SelectInputBox, Switch } from '../Common/Input';
import { AdvancedTextEditor } from "../Common/ReactQuil";
import { Table } from "../Common/Table";
import { post } from '../Common/service';
import Loader from '../Layout/Loader';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import * as React from 'react';

const ProductListing = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const navigate = useNavigate();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const userGroupName = cookies.get('userGroupName') ? cookies.get('userGroupName'):'';
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState({
        ProductDetails: true,
        ProductVarrient: true,
        ProductPricing: true,
        ProductShipping: true,
        ProductInventory: true,
        ProductMultimedia: true,
        ProductSEO: true,
        RelatedProducts: true,
    })

    const [isExpanded, setIsExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState('ImageTab');
    // Handle Tab Click to Tab Change
    const handleTabClick = (tabId) => {
        setActiveTab(tabId)
    };

    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [images, setImages] = useState([]);
    const [formData, setFormData] = useState({
        ProductCode: "",
        ProductID: "",
        Brand: "",
        Category: "",
        SubCategory: "",
        ProcessType: "",
        ReturnableDays: "",
        ReplaceableDays:"",
        Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
        AccountManager:"",
        varrientOf: "",
        ProductName: "",
        SkuCode: "",
        Packs: "",
        IsRequireOtherProducts: false,
        RequiredProductIDs: '',
        IsAutoAddToCart: false,
        IsScheduleAvailability: false,
        AvailableStartDate: "",
        AvailableEndDate: "",
        IsMarkAsNew: false,
        MarkAsNewStartDate: "",
        MarkAsNewEndDate: "",
        IsGITag: false,
        IsGift: false,
        TagNo:"",
        ProductTags: [],
        ShortDescription: "",
        FullDescription: "",
        AdminComment: "",
        IsProductAvailabe: false,
        IsReplace: false,
        IsReturnable: false,
        IsPublished: false,
        IsAllowCustomerReviews: true,
        MrpAmount: '',
        SellingPrice: '',
        COD: true,
        DiscountType: 'PERCENTAGE',
        Discount: '',
        IsDisableBuyBtn: false,
        IsDisableWishlistBtn: false,
        IsTaxExempt: false,
        TaxCategory: '',
        StockCount: '',
        HSNCode: '',
        Barcode: '',
        Unit: '',
        WeightValue: '',
        Length: '',
        Height: '',
        Width: '',
        ShippingWeight: '',
        IsFreeShipping: false,
        IsShipSeparately: false,
        StockQuantity: '',
        LowStockActivity: '',
        NotifyForQtyBelow: '',
        MinimumCartQty: '1',
        MaximumCartQty: '',
        IsDisplayAvailability: false,
        WarehouseList: [],
        ProuductPropertyList: [],
        BulkImageList: [],
        VideoLinkList: [],
        SearchEnginePageName: '',
        MetaTitle: '',
        MetaKeywords: '',
        MetaDescription: '',
        RelatedProducts: [],
    });

    const [videoLinkForm, setVideoLinkForm] = useState({
        sequence: '',
        imgName: '',
        imgExt: "mp4",
        img: '',
        isPrimaryImage: false,
        type: 'Video'
    })
    const [productUrl, setProductUrl] = useState("")


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');

    const [warehouseForm, setWarehouseForm] = useState({
        warehouseID: '',
        warehouseName: '',
        stockQty: '',
    })

    const [PropertyForm, setPropertyForm] = useState({
        productPropertyId: "",
        productProperty: "",
        propertyValue: ""

    })

    const [isWarehouseEdit, setIsWarehouseEdit] = useState(false)
    const [warehouseEditIndex, setWarehouseEditIndex] = useState(false)

    const [isPropertyEdit, setIsPropertyEdit] = useState(false)
    const [PropertyEditIndex, setPropertyEditIndex] = useState(false)

    const [ProductTagData, setProductTagData] = useState([])
    const [RequiredProductsData, setRequiredProductsData] = useState([])
    
    const handleImageChange = (e) => {
        const files = e.target.files;
        const validFileTypes = ['image/jpeg', 'image/png'];
        const newImages = [];
        const updatedFormData = { ...formData };
        const updatedBulkImageList = [...updatedFormData.BulkImageList];
        const currentLength = images.length;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const extArr = file.name.split(".");
            const reader = new FileReader();

            if (validFileTypes.includes(file.type)) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let logo = reader.result.split(',')[1];
                    newImages.push({
                        imgName: extArr[0],
                        imgExt: extArr[1],
                        img: logo,
                        isPrimaryImage: i === 0 && currentLength === 0,
                        type: 'Image',
                        sequence: `${currentLength + i + 1}`
                    });
                    if (newImages.length === files.length) {
                        const allImages = [...images, ...newImages];
                        const sortedImages = sortImagesByPrimary(allImages);
                        setImages(sortedImages);
                        setFormData({
                            ...formData,
                            BulkImageList: sortedImages
                        });
                    }
                };
            } else {
                alert('Only JPEG and PNG files are allowed');
            }
        }
    }


    const HandleMakePrimary = (index) => {
        const updatedImages = images.map((image, i) => ({
            ...image,
            isPrimaryImage: i === index
        }));

        const sortedImages = sortImagesByPrimary(updatedImages);
        setImages(sortedImages);
        setFormData({ ...formData, BulkImageList: sortedImages });
    }

    const sortImagesByPrimary = (images) => {
        return images.sort((a, b) => b.isPrimaryImage - a.isPrimaryImage);
    }

    const handleDeleteImage = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this Image/Video. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedImages = [...images];
                updatedImages.splice(index, 1);

                // Ensure the first image is always the primary image and update sequences
                if (updatedImages.length > 0) {
                    updatedImages[0].isPrimaryImage = true;
                    for (let i = 1; i < updatedImages.length; i++) {
                        updatedImages[i].isPrimaryImage = false;
                    }
                }

                // Update the sequence numbers
                const reSequencedImages = updatedImages.map((image, i) => ({
                    ...image,
                    sequence: `${i + 1}`
                }));

                setImages(reSequencedImages);
                setFormData({
                    ...formData,
                    BulkImageList: reSequencedImages
                });

                Swal.fire('Deleted!', 'The Image/Video has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'The Image/Video was not deleted.', 'info');
            }
        });
    };


    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';


    // Display data and GETDAT
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getProductListingShow = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowProductMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (showForm && type !== 'add') {
            Swal.fire({
                title: 'Do you want to leave the form?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    // User confirmed, toggle modal
                    toggleModalAction(type);
                }
            });
        } else {
            toggleModalAction(type);

        }
    };

    const toggleModalAction = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);

        }
        else {
            setShowForm(false);
            setShowGrid(true);
        }
    };

    let GetVarientOf = DropDownData('GetProductVarrientOf', showForm, formData.Brand, formData.Category, formData.SubCategory, formData.Vendor)
    let GetOrderOptions = DropDownData('GetOPSProcessType', showForm, formData.SubCategory);
    let GetUnitList = DropDownData('GetUnit', showForm);
    let GetTaxCategory = DropDownData('GetTaxCategory', showForm);
    let GetWarehouse = DropDownData('GetWarehouse', showForm);

    const handleClear = () => {
        setFormData(() => ({
            ProductCode: "",
            ProductID: "",
            Brand: "",
            Category: "",
            SubCategory: "",
            ProcessType: "",
            ReturnableDays: "",
            ReplaceableDays:"",
            Vendor: VendorCode ? VendorCode + ' : ' + VendorName : "",
            AccountManager:"",
            varrientOf: "",
            ProductName: "",
            SkuCode: "",
            Packs: "",
            IsRequireOtherProducts: false,
            RequiredProductIDs: '',
            IsAutoAddToCart: false,
            IsScheduleAvailability: false,
            AvailableStartDate: "",
            AvailableEndDate: "",
            IsMarkAsNew: false,
            MarkAsNewStartDate: "",
            MarkAsNewEndDate: "",
            IsGITag: false,
            IsGift: false,
            TagNo:'',
            ProductTags: [],
            ShortDescription: "",
            FullDescription: "",
            AdminComment: "",
            IsProductAvailabe: false,
            IsReplace: false,
            IsReturnable: false,
            IsPublished: false,
            IsAllowCustomerReviews: true,
            MrpAmount: '',
            SellingPrice: '',
            COD: true,
            DiscountType: 'PERCENTAGE',
            Discount: '',
            IsDisableBuyBtn: false,
            IsDisableWishlistBtn: false,
            IsTaxExempt: false,
            TaxCategory: '',
            StockCount: '',
            HSNCode: '',
            Barcode: '',
            Unit: '',
            WeightValue: '',
            Length: '',
            Height: '',
            Width: '',
            ShippingWeight: '',
            IsFreeShipping: false,
            IsShipSeparately: false,
            StockQuantity: '',
            LowStockActivity: '',
            NotifyForQtyBelow: '',
            MinimumCartQty: '1',
            MaximumCartQty: '',
            IsDisplayAvailability: false,
            WarehouseList: [],
            BulkImageList: [],
            VideoLinkList: [],
            ProuductPropertyList: [],
            SearchEnginePageName: '',
            MetaTitle: '',
            MetaKeywords: '',
            MetaDescription: '',
            RelatedProducts: [],
        }))
        setImages([])
        filterData.SearchText = '';
        setEdit(false)
        setColoredRow(null)
        //setSortConfig({ SortColumn: null, SortOrder: null })
    }
    
    const handleSubmit = async () => {
        setIsLoading(true);
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const sequenceValid = formData.BulkImageList.every((image, index) => image.sequence == index + 1);
        if (!sequenceValid) {
            setIsLoading(false);
            Swal.fire({
                title: 'Error!',
                text: 'The image sequence is not correct. Please check and try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source,
            ProductTags: formData.ProductTags ? formData?.ProductTags?.map(ele => ele.value).join(",") : "",
            RequiredProductIDs: formData.RequiredProductIDs ? formData.RequiredProductIDs.map(ele => ele.value).join(",") : "",
            RelatedProducts: formData.RelatedProducts ? formData.RelatedProducts.map(ele => ele.value).join(",") : "",
        };

        post(`${API_SERVER}/api/Master/InsertUpdateProductMaster`, { ...data }, async (res) => {
            await setIsLoading(false);
            if (res.status === 'SUCCESS') {
                setProductUrl(res.productPreviewUrl)
                const result = await Swal.fire({
                    title: 'Success!',
                    text: res.message,
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Go to Product List',
                    cancelButtonText: 'Stay on Form'
                });

                if (result.isConfirmed) {
                    setShowGrid(true);
                    setShowForm(false);
                } else {
                    setShowGrid(false);
                    setShowForm(true);

                }
            } else if (res.message === "EXPIRED") {
                navigate('/Login');
            } else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
        });
    };
    const handlePreviewProduct = () => {
        window.open(productUrl, '_blank');
        // console.log(productUrl,"dfhsdgsfgsdhg")
    };
    const ProductPreview = (ProductUrl) => {
        window.open(ProductUrl, '_blank')
    }
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditProductMaster`, { ...data, ProductID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setProductUrl(res.productPreviewUrl)
                setFormData({
                    ...formData,
                    ProductCode: res.productCode,
                    ProductID: res.productID,
                    Brand: res.brand,
                    Category: res.category,
                    SubCategory: res.subCategory,
                    ProcessType: res.processType,
                    Vendor: res.vendor,
                    varrientOf: res.varrientOf,
                    ProductName: res.productName,
                    ReturnableDays: res.returnableDays,
                    ReplaceableDays: res.replaceableDays,
                    SkuCode: res.skuCode,
                    Packs: res.packs,
                    IsRequireOtherProducts: res.isRequireOtherProducts,
                    RequiredProductIDs: res.requiredProductIDs === (null || '') ? [] : res.requiredProductIDs.split(',').map(ele => ({ value: ele.split(' ')[0], label: ele })),
                    AccountManager:res.accountManager,
                    IsAutoAddToCart: res.isAutoAddToCart,
                    IsScheduleAvailability: res.isScheduleAvailability,
                    AvailableStartDate: res.availableStartDate,
                    AvailableEndDate: res.availableEndDate,
                    IsMarkAsNew: res.isMarkAsNew,
                    MarkAsNewStartDate: res.markAsNewStartDate,
                    MarkAsNewEndDate: res.markAsNewEndDate,
                    IsGITag: res.isGITag,
                    IsGift: res.isGift,
                    TagNo: res.tagNo,
                    ProductTags: res.productTags.split(',').map(ele => ({ value: ele.split(' ')[0], label: ele })),
                    ShortDescription: res.shortDescription,
                    FullDescription: res.fullDescription,
                    AdminComment: res.adminComment,
                    IsProductAvailabe: res.isProductAvailabe,
                    IsReplace: res.isReplace,
                    IsReturnable: res.isReturnable,
                    IsPublished: res.isPublished,
                    IsAllowCustomerReviews: res.isAllowCustomerReviews,
                    MrpAmount: res.mrpAmount,
                    SellingPrice: res.sellingPrice,
                    COD: res.cod,
                    DiscountType: res.discountType,
                    Discount: res.discount,
                    IsDisableBuyBtn: res.isDisableBuyBtn,
                    IsDisableWishlistBtn: res.isDisableWishlistBtn,
                    IsTaxExempt: res.isTaxExempt,
                    TaxCategory: res.taxCategory,
                    StockCount: res.stockCount,
                    HSNCode: res.hsnCode,
                    Barcode: res.barcode,
                    Unit: res.unit,
                    WeightValue: res.weightValue,
                    Length: res.length,
                    Height: res.height,
                    Width: res.width,
                    ShippingWeight: res.shippingWeight,
                    IsFreeShipping: res.isFreeShipping,
                    IsShipSeparately: res.isShipSeparately,
                    StockQuantity: res.stockQuantity,
                    LowStockActivity: res.lowStockActivity,
                    NotifyForQtyBelow: res.notifyForQtyBelow,
                    MinimumCartQty: res.minimumCartQty,
                    MaximumCartQty: res.maximumCartQty,
                    IsDisplayAvailability: res.isDisplayAvailability,
                    WarehouseList: res.warehouseList === null ? [] : res.warehouseList,
                    BulkImageList: res.bulkImageList === null ? [] : res.bulkImageList,
                    ProuductPropertyList: res.prouductPropertyList === null ? [] : res.prouductPropertyList,
                    VideoLinkList: [],
                    SearchEnginePageName: res.searchEnginePageName,
                    MetaTitle: res.metaTitle,
                    MetaKeywords: res.metaKeywords,
                    MetaDescription: res.metaDescription,
                    RelatedProducts: res.relatedProducts === '' ? [] : res.relatedProducts.split(',').map(ele => ({ value: ele.split(' ')[0], label: ele })),

                });

                setImages(res.bulkImageList === null ? [] : res.bulkImageList)
                setColoredRow(id)
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }
    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteProductMaster`, { ...data, ProductID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
                getProductListingShow()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const handleChange1 = (event, value) => {
        getProductListingShow(value)
    };
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        setPropertyForm({ ...PropertyForm, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const GetProductTagData = (value) => {
        setProductTagData([])
        let temp = {
            Token: cookies.get('token'),
            LoginUserCode: `${LoginUserCode}`,
            Type: "GetProductTags",
            Prefix: '',
            ContextKey: value ? value : '',
            ContextKey2: ""
        }
        getCommonData({ ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setProductTagData([])
                } else {
                    let data = res && res.autoCompleteData && res.autoCompleteData.length > 0 ? res.autoCompleteData : []
                    setProductTagData(data)
                }
            }
        });
    }
    const ProductTagOption = ProductTagData.map((ele) => {
        return { label: ele.values, value: ele.values.split(" ")[0] }
    });
    const handleVideoLinkChange = (e) => {
        let value = e.target.value;
        const iframeRegex = /<iframe.*?src="(.*?)"/;
        const match = value.match(iframeRegex);
        if (match) {
            value = match[1];
        }
        setVideoLinkForm({ ...videoLinkForm, imgName: value });
    };
    //Mutltiple select Box  RequiredProducts data
    const GetRequiredProducts = (value) => {
        setRequiredProductsData([])
        let temp = {
            Token: cookies.get('token'),
            LoginUserCode: `${LoginUserCode}`,
            Type: "GetNewRequiredProduct",
            Prefix: '',
            ContextKey: value ? value : '',
            ContextKey2: ""
        }
        getCommonData({ ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setRequiredProductsData([])
                } else {
                    let data = res && res.autoCompleteData && res.autoCompleteData.length > 0 ? res.autoCompleteData : []
                    setRequiredProductsData(data)
                }
            }
        });
    }
    const RequiredProductsOption = RequiredProductsData.map((ele) => {
        return { label: ele.values, value: ele.values.split(" ")[0] }
    });
    //Check Duplicate Warehouse
    const checkDuplicateWarehouse = (objectToCheck, arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].warehouseID === objectToCheck.warehouseID) {
                return true;
            }
        }
        return false;
    }
    const handleAddWarehouse = () => {
        if (warehouseForm.warehouseName==="") {
            AlertBox('Error', "Please select Warehouse!.", 'Warehouse');
        }
        else if (warehouseForm.stockQty ==="")
        {
            AlertBox('Error', "Please Fill Warehouse Stock Quantity !.", 'txtWarehouseStockQuantity');
        }
        else if (warehouseForm.stockQty === (0 || '0')) {
            AlertBox('Error', "Warehouse Stock Quantity should be greater than zero !.", 'txtWarehouseStockQuantity');
        }
        else {
            if (checkDuplicateWarehouse(warehouseForm, formData.WarehouseList) === false) {
                let updatedWarehouseList;
                if (isWarehouseEdit) {
                    updatedWarehouseList = [...formData.WarehouseList];
                    updatedWarehouseList[warehouseEditIndex] = { ...warehouseForm };

                    setIsWarehouseEdit(false);
                    setWarehouseEditIndex('');
                } else {
                    updatedWarehouseList = [...formData.WarehouseList, warehouseForm];
                }

                const totalStockQty = updatedWarehouseList.reduce((sum, warehouse) => {
                    return sum + Number(warehouse.stockQty);
                }, 0);

                setFormData({ ...formData, WarehouseList: updatedWarehouseList, StockQuantity: String(totalStockQty) });

                setWarehouseForm({
                    warehouseName: '',
                    stockQty: '',
                    warehouseID: '',
                });
            } else {
                AlertBox("Error", 'This Warehouse Already Selected.!', 'txtWarehouse');
            }
        }
    };

    //----------------Add property------------------
    const handleAddProperty = () => {
        // Validate that both productProperty and propertyValue are filled
        if (!PropertyForm.productProperty || PropertyForm.propertyValue === '') {
            if (!PropertyForm.productProperty) {
                AlertBox('Error', "Please Fill Product Property", 'txtProductProperty');
            } else if (PropertyForm.propertyValue === '') {
                AlertBox('Error', "Please Fill Property Value", 'txtPropertyValue');
            }
        } else {
            if (isPropertyEdit) {
                // Edit the existing property
                const prouductPropertyList = [...formData.ProuductPropertyList];
                prouductPropertyList[PropertyEditIndex] = { ...PropertyForm };
                setFormData({ ...formData, ProuductPropertyList: prouductPropertyList });
                setIsPropertyEdit(false);
                setPropertyEditIndex('');
            } else {
                // Add a new property
                setFormData({ ...formData, ProuductPropertyList: [...formData.ProuductPropertyList, PropertyForm] });
            }
            // Reset the form
            setPropertyForm({
                productPropertyId: '',
                productProperty: '',
                propertyValue: '',
            });
        }
    };

    const HandleEditProductProperty = (item, index) => {
        setIsPropertyEdit(true);
        setPropertyEditIndex(index);
        const editProductPropertyData = formData.ProuductPropertyList[index];
        setPropertyForm({
            ...PropertyForm,
            productProperty: editProductPropertyData.productProperty,
            propertyValue: editProductPropertyData.propertyValue,
            productPropertyId: `${index}`,
        });
        setFocus('txtProductProperty');
    };
 
    const HandleDeleteProductProperty = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this Product Property. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                const FilteredProductPropertyData = formData.ProuductPropertyList.filter((ele, i) => i !== index);
                setFormData({ ...formData, ProuductPropertyList: FilteredProductPropertyData });
                Swal.fire('Deleted!', 'The Product Property has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'The Product Property was not deleted.', 'info');
            }
        });
    };
    function convertTwoDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }
    //Handle Edit Warehouse
    const HandleEditWarehouse = (item, index) => {
        setIsWarehouseEdit(true)
        setWarehouseEditIndex(index);
        const editWarehouseData = formData.WarehouseList.find((ele, i) => i === index)
        setWarehouseForm({
            ...warehouseForm,
            warehouseName: editWarehouseData.warehouseName,
            stockQty: editWarehouseData.stockQty,
            warehouseID: `${index}`,
        })
    }

    const HandleDeleteWarehouse = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this warehouse. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                const FilteredWarehouseData = formData.WarehouseList.filter((ele, i) => i !== index);
                setFormData({ ...formData, WarehouseList: FilteredWarehouseData });
                Swal.fire('Deleted!', 'The warehouse has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'The warehouse was not deleted.', 'info');
            }
        });
    };
    
    //-----------------------------Show Image In Modal------------------------//
    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }

    //----------------------Add Video Short Link----------------//
    const handleAddLinks = () => {
        if (!videoLinkForm.imgName && !videoLinkForm.sequence) {
            AlertBox('Error', "Please Fill Value", 'txtShortVideoLink');
        } else {
            const updatedFormData = { ...formData };
            const updatedBulkImageList = [...updatedFormData.BulkImageList];
            setImages([...images, {
                ...videoLinkForm,
                imgExt: "mp4",
                img: '',
                isPrimaryImage: false,
                type: 'Video'
            }
                ,]);

            updatedBulkImageList.push(videoLinkForm);
            setFormData({
                ...formData,
                BulkImageList: updatedBulkImageList
            });
            setVideoLinkForm(
                {
                    sequence: '',
                    imgName: '',
                    imgExt: "mp4",
                    img: '',
                    type: 'Video'

                })
        }
    }
   
    const handleReplaceDaysChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, ReplaceableDays: value })
        if (value !== '' && !formData.IsReplace) {
            setFormData({ ...formData, IsReplace: true })
        }
    };

    const handleDaysChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, ReturnableDays: value });
        if (value !== '' && !formData.IsReturnable) {
            setFormData({ ...formData, IsReturnable: true });
        }
       
    };

    const handleDaysBlur = () => {
        if (formData.ReturnableDays === '') {
            setFormData({ ...formData, IsReturnable: false });
        }
        else if (formData.ReplaceableDays === "") {
            setFormData({ ...formData, IsReplace:false })
        }
    };
    
    const remainingChars = 500 - formData.MetaDescription.length;
    const remaningCharsAdmin = 250 - formData.AdminComment.length;
    const remainingCharsShortDesc = 500 - formData.ShortDescription.length
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    let status = VendorCode.toString().includes('V')
    function generateSEOName(name) {
        // Replace spaces with dashes and remove special characters
        return name.replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    }
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
        const confirmationMessage = 'Are you sure you want to leave this page? Your changes may not be saved.';
        return confirmationMessage;
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        document.title = 'Product Listng'
        setFocus("txtBrand")
        if (showForm) {
            setFocus("txtProductCode");

        }
        GetRequiredProducts()
    }, [showForm, setFocus])
    useEffect(() => {
        getProductListingShow()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                       
                                        if (!showForm) {
                                            GetProductTagData();
                                            GetRequiredProducts();

                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowProductMaster', ExportRequest, 'ProductMaster')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Product Listing'
                                />
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">
                                            <div className="Product_container my-3" >
                                                {formData?.ProductCode && <span className='bold text-danger p-2 '>Product Code : {formData.ProductCode}</span>}
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductDetails: !isCollapsed.ProductDetails }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.ProductDetails ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Main Product Details </span>

                                                    </h6>
                                                    <div  >
                                                        <i className={`fa   ${!isCollapsed.ProductDetails ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>
                                                {isCollapsed.ProductDetails ? <div className='row mt-2 mx-0'>

                                                    <AutoCompleteBox
                                                        label='Brand/Store'
                                                        placeholder='Brand/Store'
                                                        inputValue={formData.Brand ? formData.Brand : ''}
                                                        onInputChange={
                                                            (event, newInputValue) => {
                                                                handleAutoCompleteChange(event, newInputValue, 'Brand', 'GetBrand', '');
                                                                setFormData(prevFormData => ({
                                                                    ...prevFormData,
                                                                    Brand: newInputValue,
                                                                    varrientOf: "" 
                                                                }));
                                                            }}
                                                        
                                                        maxLength='10'
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required={true}
                                                        id='txtBrand'
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtCategory") };
                                                        }}
                                                    />

                                                    <AutoCompleteBox
                                                        label='Category'
                                                        inputValue={formData.Category ? formData.Category : ''}
                                                        onInputChange={
                                                            (event, newInputValue) => {
                                                                handleAutoCompleteChange(event, newInputValue, 'Category', 'GetCategory', '');
                                                            }}
                                                        maxLength='10'
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required={true}
                                                        id='txtCategory'
                                                        placeholder="Category"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("ddlSubCategory") };
                                                        }}
                                                    />


                                                    <AutoCompleteBox
                                                        label='Sub Category'
                                                        inputValue={formData.SubCategory ? formData.SubCategory : ''}
                                                        onInputChange={
                                                            (event, newInputValue) => {
                                                                handleAutoCompleteChange(event, newInputValue, 'SubCategory', 'GetSubCategoryCategoryWise', formData.Category);
                                                            }}
                                                        maxLength='10'
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required={true}
                                                        placeholder="SubCategory"
                                                        id='ddlSubCategory'
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtProcessType") };
                                                        }}
                                                    />


                                                    <SelectInputBox
                                                        id="txtProcessType"
                                                        divclassname="col col-lg-3 fields"
                                                        label="Process Type"
                                                        required={true}
                                                        value={formData.ProcessType}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, ProcessType: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                setFocus("txtVendor")
                                                            }
                                                        }}
                                                    ><option value=''>--Select Process--</option>
                                                        {
                                                            GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                                <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                            )
                                                        }
                                                    </SelectInputBox>

                                                    <AutoCompleteBox
                                                        label='Vendor'
                                                        inputValue={formData.Vendor ? formData.Vendor : ''}
                                                        onInputChange={
                                                            (event, newInputValue) => {
                                                                handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetAccountManagerWiseVendor', userGroupName.toString(), LoginUserCode.toString());
                                                            }}
                                                        maxLength='10'
                                                        disabled={status}
                                                        options={autoCompleteOptions}
                                                        placeholder="Vendor"
                                                        setOptions={setAutoCompleteOptions}
                                                        required={true}
                                                        id='txtVendor'
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtVarientOf") };

                                                        }}
                                                    />
                                                    {/*<AutoCompleteBox*/}
                                                    {/*    label='Account Manager'*/}
                                                    {/*    inputValue={formData.AccountManager ? formData.AccountManager : ''}*/}
                                                    {/*    onInputChange={*/}
                                                    {/*        (event, newInputValue) => {*/}
                                                    {/*            handleAutoCompleteChange(event, newInputValue, 'AccountManager', 'GetAccountManager', '');*/}
                                                    {/*        }}*/}
                                                    {/*    maxLength='10'*/}
                                                    {/*    disabled={status}*/}
                                                    {/*    options={autoCompleteOptions}*/}
                                                    {/*    placeholder="Account Manager"*/}
                                                    {/*    setOptions={setAutoCompleteOptions}*/}
                                                    {/*    required={true}*/}
                                                    {/*    id='txtAccountManager'*/}
                                                    {/*    onKeyDown={(e) => {*/}
                                                    {/*        if (e.key === 'Enter') { setFocus("txtVarientOf") };*/}

                                                    {/*    }}*/}
                                                    {/*/>*/}
                                                </div> : <></>}
                                            </div>
                                            <div className="Product_container mb-3">
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductVarrient: !isCollapsed.ProductVarrient }) }}>
                                                    <h6 className={` m-0 ${isCollapsed.ProductVarrient ? '' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Product variant </span>

                                                    </h6>
                                                    <div  >
                                                        <i className={`fa   ${!isCollapsed.ProductVarrient ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>
                                                {isCollapsed.ProductVarrient ?
                                                    <div className='row mt-2 mx-1'>
                                                        <SelectInputBox
                                                            id="txtVarientOf"
                                                            divclassname="col col-lg-3 fields"
                                                            label="variant Of"
                                                            value={formData.varrientOf ? formData.varrientOf :""}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, varrientOf: e.target.value })
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtProductName")
                                                                }
                                                            }}
                                                        >
                                                            <option value=''>--Select variant--</option>
                                                            {
                                                                GetVarientOf?.data?.dataList?.map((item, index) =>
                                                                    <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                                )
                                                            }
                                                        </SelectInputBox>
                                                        <InputBox
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtSKUCode") };
                                                            }}
                                                            label='Product Name'
                                                            placeholder='Product Name'
                                                            id='txtProductName'
                                                            maxLength='500'
                                                            value={formData.ProductName}
                                                            onChange={(e) => {
                                                                const productName = e.target.value;
                                                                const seoFriendlyName = generateSEOName(productName);
                                                                setFormData({ ...formData, ProductName: productName, SearchEnginePageName: seoFriendlyName });
                                                            }}
                                                            required
                                                        />

                                                        <InputBox
                                                            label="SKU Code"
                                                            placeholder="SKU Code"
                                                            id="txtSKUCode"
                                                            type="text"
                                                            maxLength='50'
                                                            value={formData.SkuCode}
                                                            onChange={(e) => setFormData({ ...formData, SkuCode: e.target.value })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtPack") };
                                                            }}

                                                        />

                                                        <InputBox
                                                            id="txtPack"
                                                            label='Pack'
                                                            placeholder='Pack'
                                                            maxLength='9'
                                                            value={formData.Packs}
                                                            onChange={(e) => setFormData({ ...formData, Packs: number(e.target.value) })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("RequiredOtherProduct") };
                                                            }}
                                                        />

                                                        {/*<div className='' >*/}
                                                        {/*    <Switch*/}
                                                        {/*        divclassname='col-lg-3 col-md-4 mt-2 col-sm-6'*/}
                                                        {/*        label='Required Other Product'*/}
                                                        {/*        id="RequiredOtherProduct"*/}
                                                        {/*        name='Required Other Product'*/}
                                                        {/*        checked={formData.IsRequireOtherProducts}*/}
                                                        {/*        onChange={(e) => setFormData({ ...formData, IsRequireOtherProducts: e.target.checked, RequiredProductIDs: '' })}*/}
                                                        {/*        onKeyDown={(e) => {*/}
                                                        {/*            if (e.key === 'Enter') {*/}
                                                        {/*                setFocus("AutomaticAddToCard")*/}
                                                        {/*            };*/}
                                                        {/*        }}*/}
                                                        {/*    />*/}
                                                        {/*    {formData.IsRequireOtherProducts && <>*/}
                                                        {/*        <MultiSelectInputBox*/}
                                                        {/*            required*/}
                                                        {/*            label='Required Other Product IDs'*/}
                                                        {/*            placeholder='Required Other Product IDs'*/}
                                                        {/*            id='RequiredOtherProductIDs'*/}
                                                        {/*            divclassname='col-md-12 mb-1'*/}
                                                        {/*            isOptionDisabled={() => formData.RequiredProductIDs.length >= 20}*/}
                                                        {/*            options={RequiredProductsOption}*/}
                                                        {/*            value={formData.RequiredProductIDs}*/}
                                                        {/*            onChange={(e) => {*/}
                                                        {/*                setFormData({ ...formData, RequiredProductIDs: e })*/}
                                                        {/*            }}*/}
                                                        {/*        />*/}
                                                        {/*        <Switch*/}
                                                        {/*            divclassname='col-lg-3 col-md-4 mt-3 col-sm-6'*/}
                                                        {/*            label='Automatic Add to Card'*/}
                                                        {/*            id="AutomaticAddToCard"*/}
                                                        {/*            name='Automatic Add to Card'*/}
                                                        {/*            checked={formData.IsAutoAddToCart}*/}
                                                        {/*            onChange={(e) => setFormData({ ...formData, IsAutoAddToCart: e.target.checked })}*/}
                                                        {/*            onKeyDown={(e) => {*/}
                                                        {/*                if (e.key === 'Enter') {*/}
                                                        {/*                    setFocus("ScheduleAvailability")*/}
                                                        {/*                };*/}
                                                        {/*            }}*/}
                                                        {/*        />*/}
                                                        {/*    </>}*/}
                                                        {/*</div>*/}
                                                        <div className='d-flex row'>
                                                            <Switch
                                                                divclassname='col-lg-3 col-md-4 mt-2 col-sm-6'
                                                                label='Schedule Availability'
                                                                id="ScheduleAvailability"
                                                                name='Schedule Availability'
                                                                checked={formData.IsScheduleAvailability}
                                                                onChange={(e) => setFormData({ ...formData, IsScheduleAvailability: e.target.checked, AvailableStartDate: "", AvailableEndDate: '' })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        setFocus("txtAvailableStartDate")
                                                                    };
                                                                }}

                                                            />
                                                            {formData.IsScheduleAvailability && <><DateBox
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        setFocus("txtAvailableEndDate")
                                                                    };
                                                                }}
                                                                showTimeSelect={true}
                                                                dateFormat="dd-MMM-yyyy h:mm aa"
                                                                label='Available Start Date'
                                                                id='txtAvailableStartDate'
                                                                minDate={new Date()}
                                                                selected={formData.AvailableStartDate === "" ? "" : new Date(formData.AvailableStartDate)}
                                                                placeholder="Available Start Date"
                                                                onChange={(e) => setFormData({ ...formData, AvailableStartDate: dateFormateWithTime(e) })}
                                                                tooltip='Mark as Available Start Date Should be Greater or equal to Current DateTime'
                                                            />

                                                                <DateBox
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            setFocus("MarkAsNew")
                                                                        };
                                                                    }}
                                                                    showTimeSelect={true}
                                                                    dateFormat="dd-MMM-yyyy h:mm aa"
                                                                    label='Available End Date'
                                                                    id='txtAvailableEndDate'
                                                                    minDate={new Date()}
                                                                    selected={formData.AvailableEndDate === "" ? "" : new Date(formData.AvailableEndDate)}
                                                                    placeholder="Available End Date"
                                                                    onChange={(e) => setFormData({ ...formData, AvailableEndDate: dateFormateWithTime(e) })}
                                                                    tooltip='Mark as Available End Date Should be Greater or equal to Current DateTime'
                                                                />

                                                            </>}
                                                        </div>
                                                        <div className='row '>
                                                            <Switch
                                                                divclassname='col-lg-3 col-md-4 mt-2 col-sm-6'
                                                                label='Mark as New'
                                                                id="MarkAsNew"
                                                                name='Mark As New'
                                                                checked={formData.IsMarkAsNew}
                                                                onChange={(e) => setFormData({ ...formData, IsMarkAsNew: e.target.checked, MarkAsNewStartDate: '', MarkAsNewEndDate: '' })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        setFocus("txtMarkAsNewStartDate")
                                                                    };
                                                                }}
                                                            />
                                                            {formData.IsMarkAsNew && <>
                                                                <DateBox
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            setFocus("txtMarkAsNewEndDate")
                                                                        };
                                                                    }}
                                                                    showTimeSelect={true}
                                                                    minDate={new Date()}
                                                                    dateFormat="dd-MMM-yyyy h:mm aa"
                                                                    label='Mark as New Start Date'
                                                                    id='txtMarkAsNewStartDate'
                                                                    selected={formData.MarkAsNewStartDate === "" ? "" : new Date(formData.MarkAsNewStartDate)}
                                                                    placeholder="Mark as New  Start Date"
                                                                    onChange={(e) => setFormData({ ...formData, MarkAsNewStartDate: dateFormateWithTime(e) })}
                                                                    tooltip='Mark as New Start Date Should be Greater or equal to Current DateTime'
                                                                />

                                                                <DateBox
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            setFocus("IsGiTag")
                                                                        };
                                                                    }}
                                                                    showTimeSelect={true}
                                                                    minDate={new Date()}
                                                                    dateFormat="dd-MMM-yyyy h:mm aa"
                                                                    label='Mark as New  End Date'
                                                                    id='txtMarkAsNewEndDate'
                                                                    selected={formData.MarkAsNewEndDate === "" ? "" : new Date(formData.MarkAsNewEndDate)}
                                                                    placeholder="Mark as New  End Date"
                                                                    onChange={(e) => setFormData({ ...formData, MarkAsNewEndDate: dateFormateWithTime(e) })}
                                                                    tooltip='Mark as New End Date Should be Greater or equal to Current DateTime'
                                                                />
                                                            </>}
                                                        </div>
                                                        <div className='row '>
                                                            <Switch
                                                                divclassname='col-lg-3 col-md-4 mt-2 col-sm-6'
                                                                label='IsGiTag'
                                                                id="IsGiTag"
                                                                name='IsGiTag'
                                                                checked={formData.IsGITag}
                                                                onChange={(e) => setFormData({ ...formData, IsGITag: e.target.checked, TagNo:'' })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        setFocus("txtTagNo")
                                                                    };
                                                                }}
                                                            />
                                                            {formData.IsGITag && <>
                                                                <InputBox
                                                                    label="Tag No"
                                                                    placeholder="Tag No"
                                                                    id="txtTagNo"
                                                                    type="text"
                                                                    maxLength='15'
                                                                    value={formData.TagNo}
                                                                    onChange={(e) => setFormData({ ...formData, TagNo: e.target.value })}

                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("IsGift") };
                                                                    }}

                                                                />
                                                               
                                                            </>}
                                                        </div>
                                                        <Switch
                                                            divclassname='col-lg-3 col-md-4 mt-2 col-sm-6'
                                                            label='Gift'
                                                            id="IsGift"
                                                            name='IsGift'
                                                            checked={formData.IsGift}
                                                            onChange={(e) => setFormData({ ...formData, IsGift: e.target.checked, TagNo: '' })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("ProductTags")
                                                                };
                                                            }}
                                                        />
                                                        <MultiValueSelectBox
                                                            required
                                                            label='Product Tags'
                                                            extraLabel='(Type your tag this will help to find you product)'
                                                            placeholder='Product Tags'
                                                            id='ProductTags'
                                                            divclassname='col-md-12 mb-1'
                                                            options={ProductTagOption}
                                                            value={formData.ProductTags}
                                                            isOptionDisabled={() => formData.ProductTags.length >= 20}

                                                            onChange={(e) => {
                                                                setFormData({ ...formData, ProductTags: e })
                                                            }}
                                                        />

                                                        <div className="col-lg-12 ">
                                                            <div className="fields">
                                                                <div className="d-flex justify-content-between">
                                                                    <label className="form-label">Short Description<span className="Required">*</span></label> <span>{remainingCharsShortDesc}/500</span></div>
                                                                <textarea
                                                                    className="col-12"
                                                                    id="txtShortDescription"
                                                                    name='ShortDescription'
                                                                    rows={3}
                                                                    maxLength={500}
                                                                    value={formData.ShortDescription}
                                                                    onChange={(e) => {
                                                                        setFormData({ ...formData, ShortDescription: e.target.value })
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("txtProductProperty") };
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12 " >

                                                            <div className="d-flex justify-content-between"><label className="form-label" >Full Description<span className="Required">*</span></label>
                                                            </div>
                                                            <div>
                                                                <div >
                                                                    <AdvancedTextEditor
                                                                        id='txtFullDescription'
                                                                        value={formData.FullDescription}
                                                                        setValue={(e) => setFormData({ ...formData, FullDescription: e })}
                                                                        className='textEditor'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12 my-3'>
                                                            <div className="Product_container1">
                                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" >
                                                                    <h6 className="m-0  " style={{ fontWeight: "bold" }} >
                                                                        <span className=''>Product Property </span>

                                                                    </h6>

                                                                </div>
                                                                <div className='row mx-0 px-0 mt-3'>
                                                                    <AutoCompleteBox
                                                                        label='Product Property'
                                                                        inputValue={PropertyForm.productProperty ? PropertyForm.productProperty : ''}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleAutoCompleteChange(event, newInputValue, 'productProperty', 'GetProductProperty', formData.Category);
                                                                        }}
                                                                        maxLength='10'
                                                                        required={true }
                                                                        options={autoCompleteOptions}
                                                                        setOptions={setAutoCompleteOptions}
                                                                        placeholder="Product Property"
                                                                        id='txtProductProperty'
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') { setFocus("txtPropertyValue") };
                                                                        }}
                                                                    />

                                                                    <InputBox
                                                                        id="txtPropertyValue"
                                                                        label='Property Value'
                                                                        placeholder='Property Value'
                                                                        maxLength=''
                                                                        required={true }
                                                                        value={PropertyForm.propertyValue}
                                                                        onChange={(e) => setPropertyForm({ ...PropertyForm, propertyValue: e.target.value })}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') { setFocus("txtAdminComment") };
                                                                        }}
                                                                    />
                                                                    <div className='col-md-3 col-sm-6 mt-md-4 mt-3'>
                                                                        <button className="btn btn-sm btn-success" onClick={handleAddProperty}>
                                                                            <i className="bi bi-plus-lg" /> Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {formData.ProuductPropertyList?.length > 0 ?
                                                                    <div className='pt-3 '>
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped">
                                                                                <thead>
                                                                                    <tr className='light-bg'>
                                                                                        <th className='' >Action</th>
                                                                                        <th >Product Property</th>
                                                                                        <th >Property Value</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>


                                                                                    {formData.ProuductPropertyList.map((item, index) => {
                                                                                        return <tr key={index}>
                                                                                            <td className=''>
                                                                                                <i className="fa-regular fa-pen-to-square text-success me-2" style={{ cursor: 'pointer' }} onClick={() => HandleEditProductProperty(item, index)} />
                                                                                                <i className="fa fa-trash-can text-danger me-2" style={{ cursor: 'pointer' }} onClick={() => HandleDeleteProductProperty(index)} /></td>
                                                                                            <td>{item?.productProperty}</td>
                                                                                            <td>{item?.propertyValue}</td>
                                                                                        </tr>
                                                                                    })}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    : <></>}
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-12 ">
                                                            <div className="fields">
                                                                <div className="d-flex justify-content-between">  <label className="form-label"> Admin Comment</label> <span>{remaningCharsAdmin}/250</span></div>
                                                                <textarea
                                                                    id="txtAdminComment"
                                                                    className="col-12"
                                                                    name='AdminComment'
                                                                    rows={3}
                                                                    maxLength={250}
                                                                    value={formData.AdminComment}
                                                                    onChange={(e) => {
                                                                        setFormData({ ...formData, AdminComment: e.target.value })
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("ProductAvailability") };
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>



                                                        <Switch
                                                            divclassname='d-none'
                                                            id="ProductAvailability"
                                                            label='Product Availability'
                                                            checked={formData.IsProductAvailabe}
                                                            onChange={(e) => setFormData({ ...formData, IsProductAvailabe: e.target.checked })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("NotReplaceable")
                                                                };
                                                            }}
                                                        />


                                                        <Switch
                                                            // divclassname='mt-4'
                                                            label=' Replaceable'
                                                            id="NotReplaceable"
                                                            name=' Replaceable'
                                                            checked={formData.IsReplace}
                                                            onChange={(e) => setFormData({ ...formData, IsReplace: e.target.checked })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtReplaceableDays")
                                                                };
                                                            }}
                                                        />
                                                        {formData.IsReplace && (
                                                            <InputBox
                                                                id="txtReplaceableDays"
                                                                label='Replaceable Days'
                                                                placeholder='Replaceable Days'
                                                                maxLength='2'
                                                                required={true}
                                                                value={formData.ReplaceableDays}
                                                                onChange={(e) => setFormData({
                                                                    ...formData, ReplaceableDays: e.target.value
                                                                })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("NotReturnable") };
                                                                }}
                                                               // onBlur={handleDaysBlur}
                                                            />
                                                        )}

                                                        <Switch
                                                            label='Returnable'
                                                            id="NotReturnable"
                                                            name='Returnable'
                                                            checked={formData.IsReturnable}
                                                            onChange={(e) => setFormData({ ...formData, IsReturnable: e.target.checked, })}
                                                        />
                                                        {formData.IsReturnable && (
                                                            <InputBox
                                                                id="txtReturnableDays"
                                                                label='Returnable Days'
                                                                placeholder='Returnable Days'
                                                                maxLength='2'
                                                                required={true}
                                                                value={formData.ReturnableDays}
                                                                onChange={(e) => setFormData({
                                                                    ...formData, ReturnableDays: e.target.value
                                                                })}
                                                              //  onChange={handleDaysChange}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("Publish") };
                                                                }}
                                                               // onBlur={handleDaysBlur}
                                                            />
                                                        )}

                                                        <Switch
                                                            label='Publish'
                                                            id="Publish"
                                                            name='Publish'
                                                            checked={formData.IsPublished}
                                                            onChange={(e) => setFormData({ ...formData, IsPublished: e.target.checked })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("AllowCustomerReviews")
                                                                };
                                                            }}
                                                        />
                                                        <Switch
                                                            label='Allow Customer Reviews'
                                                            id="AllowCustomerReviews"
                                                            name='Required Other Product'
                                                            checked={formData.IsAllowCustomerReviews}
                                                            onChange={(e) => setFormData({ ...formData, IsAllowCustomerReviews: e.target.checked })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtMRPAmount")
                                                                };
                                                            }}
                                                        />


                                                    </div>
                                                    : <></>}
                                            </div>
                                            <div className="Product_container mb-3">
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductPricing: !isCollapsed.ProductPricing }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.ProductPricing ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Product Pricing </span>
                                                    </h6>
                                                    <div  >
                                                        <i className={`fa   ${!isCollapsed.ProductPricing ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>
                                                {isCollapsed.ProductPricing ?
                                                    <div className='row mt-2 mx-1'>
                                                        <InputBox
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    if ((Number(formData.Discount) > Number(e.target.value)) && formData.DiscountType === 'FLAT') {
                                                                        setFormData({
                                                                            ...formData,
                                                                            Discount: '',
                                                                            SellingPrice: ''
                                                                        })
                                                                        AlertBox('error', 'Discount Should be less then MRP Amount !', 'txtDiscount')
                                                                        setFocus("txtDiscount");
                                                                    } else if ((Number(formData.Discount) > 100) && formData.DiscountType !== 'FLAT') {
                                                                        setFormData({
                                                                            ...formData,
                                                                            Discount: '',
                                                                            SellingPrice: ''
                                                                        })
                                                                        AlertBox('error', 'Discount Should be less then 100 % !', 'txtDiscount')
                                                                        setFocus("txtDiscount");
                                                                    } else {
                                                                        setFocus("DisableBuyButton");
                                                                    }
                                                                };
                                                            }}
                                                            label='MRP Amount'
                                                            placeholder='MRP Amount'
                                                            id="txtMRPAmount"
                                                            maxLength='20'
                                                            value={formData.MrpAmount}
                                                            onChange={(e) => {
                                                                let mrp = Number(e.target.value);
                                                                let dType = formData.DiscountType;
                                                                let discount = Number(formData.Discount);
                                                                if (dType === 'FLAT') {
                                                                    discount = (discount < mrp) ? discount : '0'
                                                                } else {
                                                                    discount = discount <= 100 ? discount : '0'
                                                                }
                                                                let SellingPrice = (dType === 'FLAT') ? (mrp - discount) : (mrp - (mrp * discount / 100));
                                                                setFormData({
                                                                    ...formData,
                                                                    MrpAmount: number(e.target.value),
                                                                    SellingPrice: SellingPrice.toString()
                                                                })
                                                            }}
                                                            required
                                                        />

                                                        <div className="col-md-3 col-sm-6 fields mb-0">
                                                            <div className="fields">
                                                                <label className="form-label">Discount Type<span className="Required">*</span></label>
                                                                <br></br>
                                                                <div className="btn-group w-100">
                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="Flat"
                                                                        id="Flat"
                                                                        value="FLAT"
                                                                        autoComplete="off"
                                                                        checked={formData.DiscountType === 'FLAT'}
                                                                        onChange={(e) => setFormData({
                                                                            ...formData, DiscountType: "FLAT", Discount: '',
                                                                            SellingPrice: ''
                                                                        })}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                setFocus("Percentage")
                                                                            };
                                                                        }}
                                                                    />
                                                                    <label className={`btn ${formData.DiscountType === 'FLAT' ? 'btn-warning' : 'btn-light'}`} htmlFor="Flat">
                                                                        Flat
                                                                    </label>
                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="Flat"
                                                                        id="Percentage"
                                                                        value="PERCENTAGE"
                                                                        autoComplete="off"
                                                                        checked={formData.DiscountType === 'PERCENTAGE'}
                                                                        onChange={(e) => setFormData({
                                                                            ...formData, DiscountType: "PERCENTAGE", Discount: '',
                                                                            SellingPrice: ''
                                                                        })}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') { setFocus("txtDiscount") };
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className={`btn ${formData.DiscountType === 'PERCENTAGE' ? 'btn-warning' : 'btn-light'}`}
                                                                        htmlFor="Percentage"
                                                                    >
                                                                        Percentage
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <InputBox
                                                            required
                                                            id="txtDiscount"
                                                            label={formData.DiscountType === 'FLAT' ? 'Discount' : 'Discount (%)'}
                                                            placeholder={formData.DiscountType === 'FLAT' ? 'Discount' : 'Discount (%)'}
                                                            maxLength='20'
                                                            value={formData.Discount}
                                                            onChange={(e) => {
                                                                let mrp = Number(formData.MrpAmount);
                                                                let dType = formData.DiscountType;
                                                                let discount = Number(e.target.value);
                                                                if (dType === 'FLAT') {
                                                                    discount = (discount < mrp) ? discount : '0'
                                                                } else {
                                                                    discount = discount <= 100 ? discount : '0'
                                                                }
                                                                let SellingPrice = (dType === 'FLAT')
                                                                    ? (mrp - discount)
                                                                    : (mrp - (mrp * discount / 100));
                                                                setFormData({
                                                                    ...formData,
                                                                    Discount: number(e.target.value),
                                                                    SellingPrice: SellingPrice.toString()
                                                                })
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    if ((Number(e.target.value) > Number(formData.MrpAmount)) && formData.DiscountType === 'FLAT') {
                                                                        setFormData({
                                                                            ...formData,
                                                                            Discount: '',
                                                                            SellingPrice: ''
                                                                        })
                                                                        AlertBox('error', 'Discount Should be less then MRP Amount !', 'txtDiscount')
                                                                        setFocus("txtDiscount");
                                                                    } else if ((Number(e.target.value) > 100) && formData.DiscountType !== 'FLAT') {
                                                                        setFormData({
                                                                            ...formData,
                                                                            Discount: '',
                                                                            SellingPrice: ''
                                                                        })
                                                                        AlertBox('error', 'Discount Should be less then 100 % !', 'txtDiscount')
                                                                        setFocus("txtDiscount");
                                                                    } else {
                                                                        setFocus("DisableBuyButton");
                                                                    }
                                                                };
                                                            }}
                                                        />
                                                        <InputBox
                                                            id="txtSellingPrice"
                                                            label='Selling Price'
                                                            placeholder='Selling Price'
                                                            maxLength='20'
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("COD") };
                                                            }}
                                                            value={formData.SellingPrice}
                                                            onChange={(e) => setFormData({ ...formData, SellingPrice: number(e.target.value) })}
                                                            required
                                                            disabled
                                                        />
                                                        <Switch
                                                            label='COD'
                                                            id="COD"
                                                            name='COD'
                                                            checked={formData.COD}
                                                            onChange={(e) => setFormData({ ...formData, COD: e.target.checked, })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("DisableWishlistButton")
                                                                };
                                                            }}
                                                        />
                                                        <Switch
                                                            label='Disable Buy Button'
                                                            id="DisableBuyButton"
                                                            name='DisableBuyButton'
                                                            checked={formData.IsDisableBuyBtn}
                                                            onChange={(e) => setFormData({ ...formData, IsDisableBuyBtn: e.target.checked, })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("DisableWishlistButton")
                                                                };
                                                            }}
                                                        />

                                                        <Switch
                                                            label='Disable Wishlist button'
                                                            id="DisableWishlistButton"
                                                            name='DisableWishlistButton'
                                                            checked={formData.IsDisableWishlistBtn}
                                                            onChange={(e) => setFormData({ ...formData, IsDisableWishlistBtn: e.target.checked, })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("TaxExempt")
                                                                };
                                                            }}
                                                        />

                                                        <Switch
                                                            label='Tax exempt'
                                                            id="TaxExempt"
                                                            name='Tax exempt'
                                                            checked={formData.IsTaxExempt}
                                                            onChange={(e) => setFormData({ ...formData, IsTaxExempt: e.target.checked, TaxCategory: '' })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtTaxCategory")
                                                                };
                                                            }}
                                                        />
                                                        {formData.IsTaxExempt ? <></> :
                                                            <SelectInputBox
                                                                id='txtTaxCategory
'
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        setFocus("txtStackCount")
                                                                    };
                                                                }}
                                                                label='Tax Category'
                                                                value={formData.TaxCategory ? formData.TaxCategory : ''}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData, TaxCategory: e.target.value,
                                                                    })
                                                                }}
                                                                required={true}
                                                                children={<>
                                                                    <option value=''>--Select--</option>
                                                                    {GetTaxCategory?.data?.dataList?.map((item, index) =>
                                                                        <option key={item.name + index} diviser={item.code} value={item.name}>{item.name}</option>

                                                                    )}
                                                                </>}
                                                            />
                                                        }

                                                    </div> : <></>}
                                            </div>
                                            <div className="Product_container mb-3 ">
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductShipping: !isCollapsed.ProductShipping }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.ProductShipping ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Product Shipping </span>

                                                    </h6>
                                                    <div>
                                                        <i className={`fa   ${!isCollapsed.ProductShipping ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>{isCollapsed.ProductShipping ?
                                                    <div className='row mt-2 mx-1'>
                                                        <InputBox
                                                            required
                                                            id="txtStackCount"
                                                            label='Stock Count'
                                                            placeholder='Stock Count'
                                                            value={formData.StockCount}
                                                            onChange={(e) => {
                                                                let { value } = e.target;
                                                                const stockCount = number(value);
                                                                if (!isNaN(stockCount)) {
                                                                    const PercentageStockCount = stockCount > 50 ? '' : stockCount;
                                                                    setFormData({ ...formData, StockCount: PercentageStockCount });
                                                                } else {
                                                                    setFormData({ ...formData, StockCount: '' });
                                                                }
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtHSNCode")
                                                                };
                                                            }}
                                                        />

                                                        <InputBox
                                                            required
                                                            id="txtHSNCode"
                                                            placeholder='HSN Code'
                                                            label='HSN Code'
                                                            maxLength='10'
                                                            minLength='4'
                                                            value={formData.HSNCode}
                                                            onChange={(e) => setFormData({ ...formData, HSNCode: e.target.value })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtBARCode")
                                                                };
                                                            }}
                                                        />
                                                        <InputBox
                                                            required
                                                            id="txtBARCode"
                                                            placeholder='BAR Code'
                                                            label='BAR Code'
                                                            value={formData.Barcode}
                                                            onChange={(e) => setFormData({ ...formData, Barcode: e.target.value })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtUnit")
                                                                };
                                                            }}
                                                        />
                                                        <div className='col-md-3 col-sm-6 fields d-flex gap-1'>
                                                            <SelectInputBox
                                                                divclassname='col-4 '
                                                                id='txtUnit'
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        setFocus("txtWeightValue")
                                                                    };
                                                                }}
                                                                label='Unit'
                                                                value={formData.Unit}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData, Unit: e.target.value,
                                                                    })
                                                                }}
                                                                required={true}
                                                                inputclassname='p-1'
                                                            >
                                                                <option value=''>--Select--</option>
                                                                {GetUnitList?.data?.dataList?.map((item, index) =>
                                                                    <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>

                                                                )}
                                                            </SelectInputBox>

                                                            <InputBox
                                                                divclassname='col-8'
                                                                required
                                                                id="txtWeightValue"
                                                                label='Unit Weight'
                                                                placeholder='Unit Weight'
                                                                maxLength='10'
                                                                value={formData.WeightValue}
                                                                onChange={(e) => setFormData({ ...formData, WeightValue: e.target.value })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("txtLength") };
                                                                }}
                                                            />

                                                        </div>
                                                        <InputBox
                                                            required
                                                            id="txtLength"
                                                            placeholder="Length"
                                                            maxLength='30'
                                                            label='Length'
                                                            value={formData.Length}
                                                            onChange={(e) => setFormData({ ...formData, Length: convertTwoDecimalsOnValue(e.target.value) })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtWidth") };
                                                            }}
                                                        />
                                                        <InputBox
                                                            required
                                                            id="txtWidth"
                                                            placeholder="Width"
                                                            maxLength='30'
                                                            label='Width'
                                                            value={formData.Width}
                                                            onChange={(e) => setFormData({ ...formData, Width: numberwithDot(e.target.value) })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtHeight") };
                                                            }}
                                                        />
                                                        <InputBox
                                                            required
                                                            id="txtHeight"
                                                            placeholder="Height"
                                                            maxLength='30'
                                                            label='Height'
                                                            value={formData.Height}
                                                            onChange={(e) => setFormData({ ...formData, Height: numberwithDot(e.target.value) })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtShippingWeight") };
                                                            }}
                                                        />
                                                        <InputBox
                                                            required
                                                            id="txtShippingWeight"
                                                            placeholder="Shipping Weight (Kg)"
                                                            maxLength='30'
                                                            label='Shipping Weight (Kg)'
                                                            value={formData.ShippingWeight}
                                                            onChange={(e) => setFormData({ ...formData, ShippingWeight: convertTwoDecimalsOnValue(e.target.value) })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("FreeShipping") };
                                                            }}
                                                        />
                                                        <Switch
                                                            label='Free Shipping'
                                                            id="FreeShipping"
                                                            name='Free Shipping'
                                                            checked={formData.IsFreeShipping}
                                                            onChange={(e) => setFormData({ ...formData, IsFreeShipping: e.target.checked, })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("ShippingSeparately")
                                                                };
                                                            }}
                                                        />
                                                        <Switch
                                                            divclassname='d-none'
                                                            label='Shipping Separately'
                                                            id="ShippingSeparately"
                                                            name='Shipping Separately'
                                                            checked={formData.IsShipSeparately}
                                                            onChange={(e) => setFormData({ ...formData, IsShipSeparately: e.target.checked, })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtStockQuantity")
                                                                };
                                                            }}
                                                        />

                                                    </div>
                                                    : <></>}
                                            </div>
                                            <div className="Product_container mb-3">
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductInventory: !isCollapsed.ProductInventory }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.ProductInventory ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Product Inventory </span>

                                                    </h6>
                                                    <div >
                                                        <i className={`fa   ${!isCollapsed.ProductInventory ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>{isCollapsed.ProductInventory ?
                                                    <div className='row mt-2 mx-1 mb-2'>
                                                        <InputBox
                                                            required
                                                            id="txtStockQuantity"
                                                            label='Stock Quantity'
                                                            placeholder='Stock Quantity'
                                                            maxLength=''
                                                            value={formData.StockQuantity}
                                                            onChange={(e) => setFormData({ ...formData, StockQuantity: number(e.target.value) })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtLowStockActivity") };
                                                            }}
                                                        />
                                                        <SelectInputBox
                                                            id='txtLowStockActivity'
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtNotifyForQtyBelow")
                                                                };
                                                            }}
                                                            label='Low Stock Activity'
                                                            value={formData.LowStockActivity}
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData, LowStockActivity: e.target.value,
                                                                })
                                                            }}
                                                            required={true}
                                                            children={<>
                                                                <option value=''>--Select--</option>
                                                                <option value='Nothing'>Nothing</option>
                                                                <option value='DisableBuyButton'>Disable buy Button</option>
                                                                <option value='Unpublish'>Unpublish</option>

                                                            </>}
                                                        />

                                                        <InputBox
                                                            id="txtNotifyForQtyBelow"
                                                            label='Notify for qty below'
                                                            placeholder='Notify for qty below'
                                                            maxLength='5'
                                                            value={formData.NotifyForQtyBelow}
                                                            onChange={(e) => setFormData({ ...formData, NotifyForQtyBelow: number(e.target.value) })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtMinCartQuantity") };
                                                            }}
                                                        />
                                                        <div className='col-md-6 row mx-0 px-0'>
                                                            <InputBox
                                                                divclassname="col-md-6 col-sm-6 fields"
                                                                id="txtMinCartQuantity"
                                                                label='Min. Cart Quantity'
                                                                placeholder='Min. Cart Quantity'
                                                                maxLength='5'
                                                                value={formData.MinimumCartQty}
                                                                onChange={(e) => setFormData({ ...formData, MinimumCartQty: number(e.target.value) })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("txtMaxCartQuantity") };
                                                                }}
                                                            />
                                                            <InputBox
                                                                divclassname="col-md-6 col-sm-6 fields"
                                                                id="txtMaxCartQuantity"
                                                                label='Max. Cart Quantity'
                                                                placeholder='Max. Cart Quantity'
                                                                maxLength='5'
                                                                value={formData.MaximumCartQty}
                                                                onChange={(e) => setFormData({ ...formData, MaximumCartQty: number(e.target.value) })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("DisplayAvailability") };
                                                                }}
                                                            />
                                                        </div>

                                                        <Switch
                                                            label='Display availability'
                                                            id="DisplayAvailability"
                                                            name='Display Availability'
                                                            checked={formData.IsDisplayAvailability}
                                                            onChange={(e) => setFormData({ ...formData, IsDisplayAvailability: e.target.checked, })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("Warehouse")
                                                                };
                                                            }}
                                                        />

                                                        <div className='row mx-0 px-0 '>
                                                            <SelectInputBox
                                                                required
                                                                label='Warehouse'
                                                                placeholder='Warehouse'
                                                                id='Warehouse'
                                                                divclassname={'col-md-6 col-sm-6 fields'}
                                                                value={warehouseForm.warehouseName}
                                                                onChange={(e) => {
                                                                    GetWarehouse?.data?.dataList?.map((item, index) => item.name === e.target.value)
                                                                    GetWarehouse?.data?.dataList.filter((pre, index) => (pre.name === e.target.value ? setWarehouseForm({ ...warehouseForm, warehouseName: e.target.value, warehouseID: pre.id }) : ''))

                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("txtWarehouseStockQuantity") };
                                                                }}
                                                                children={<>
                                                                    <option value=''>--Select--</option>
                                                                    {GetWarehouse?.data?.dataList?.map((item, index) =>
                                                                        <option key={item.name + index} diviser={item.code} id={item.id} value={item.name}>{item.name}  </option>

                                                                    )}
                                                                </>}
                                                            />

                                                            <InputBox
                                                                id="txtWarehouseStockQuantity"
                                                                label='Warehouse Stock Quantity'
                                                                placeholder='Warehouse Stock Quantity'
                                                                maxLength='5'
                                                                value={warehouseForm.stockQty}
                                                                onChange={(e) => setWarehouseForm({ ...warehouseForm, stockQty: number(e.target.value) })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { handleAddWarehouse() };
                                                                }}
                                                            />
                                                            <div className='col-md-3 col-sm-6 mt-md-4'>
                                                                <button className="btn btn-sm btn-success" onClick={handleAddWarehouse}>
                                                                    <i className="bi bi-plus-lg" /> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {formData.WarehouseList?.length > 0 ?
                                                            <div className='pt-3 '>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-striped">
                                                                        <thead>
                                                                            <tr className='light-bg'>
                                                                                <th className='' >Action</th>
                                                                                <th >Warehouse Name</th>
                                                                                <th >Warehouse Stock Quantity</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>


                                                                            {formData.WarehouseList.map((item, index) => {
                                                                                return <tr key={index}>
                                                                                    <td className=''>
                                                                                        <i className="fa-regular fa-pen-to-square text-success me-2" style={{ cursor: 'pointer' }} onClick={() => HandleEditWarehouse(item, index)} />
                                                                                        <i className="fa fa-trash-can text-danger me-2" style={{ cursor: 'pointer' }} onClick={() => HandleDeleteWarehouse(index)} /></td>
                                                                                    <td>{item?.warehouseName}</td>
                                                                                    <td>{item?.stockQty}</td>
                                                                                </tr>
                                                                            })}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                    </div>
                                                    : <></>}
                                            </div>
                                            <div className="Product_container mb-3">
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductMultimedia: !isCollapsed.ProductMultimedia }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.ProductMultimedia ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Product Multimedia </span>

                                                    </h6>
                                                    <div  >
                                                        <i className={`fa   ${!isCollapsed.ProductMultimedia ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>{isCollapsed.ProductMultimedia ?
                                                    <div className='row mt-2 mx-3 '>

                                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item">
                                                                <b className={`nav-link ${activeTab === 'ImageTab' ? 'active' : ''}`}
                                                                    style={activeTab === 'ImageTab' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                                                                    id="ImageTab-tab"
                                                                    onClick={() => handleTabClick('ImageTab')}
                                                                > Images</b>
                                                            </li>
                                                            <li className="nav-item">
                                                                <b
                                                                    className={`nav-link ${activeTab === 'VideoTab' ? 'active' : ''}`}
                                                                    style={activeTab === 'VideoTab' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                                                                    id="VideoTab-tab"
                                                                    onClick={() => handleTabClick('VideoTab')}
                                                                >
                                                                    Videos
                                                                </b>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content" id="myTabContent">
                                                            <div className={`tab-pane fade ${activeTab === 'ImageTab' ? 'show active' : ''}`} id="ImageTab" role="tabpanel">
                                                                <div className='mt-2'>
                                                                    <div className="col-lg-12 d-flex flex-column" id="txtPrimaryImage">
                                                                        <div className="fields mt-3">
                                                                            <label className="form-label">Bulk Image<span className="text-danger" style={{ fontSize: "12px", fontWeight: "600" }}>  * Width  should be between (1000-1000)Pixels. Height  should be between (1050-1050)Pixels.
                                                                            </span></label>
                                                                            <input
                                                                                className='form-control form-control-sm'
                                                                                type="file"
                                                                                multiple
                                                                                onChange={handleImageChange}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab === 'VideoTab' ? 'show active' : ''}`} id="VideoTab" role="tabpanel">
                                                                <div className='row mt-2'>
                                                                    <InputBox
                                                                        divclassname='col-md-6 col-sm-6 fields'
                                                                        id="txtShortVideoLink"
                                                                        label='Short Video Link'
                                                                        placeholder='Short Video Link'
                                                                        extraLabel='(Only Youtube Embed Link)'
                                                                        maxLength='300'
                                                                        value={videoLinkForm.imgName}
                                                                        // onChange={(e) => setVideoLinkForm({ ...videoLinkForm, imgName: e.target.value })}
                                                                        onChange={handleVideoLinkChange}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') { setFocus("txtSequence") };
                                                                        }}
                                                                    />
                                                                    <InputBox
                                                                        id="txtSequence"
                                                                        label='Display Sequence'
                                                                        placeholder='Display Sequence'
                                                                        maxLength='3'
                                                                        value={videoLinkForm.sequence}
                                                                        onChange={(e) => setVideoLinkForm({ ...videoLinkForm, sequence: number(e.target.value) })}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') { handleAddLinks() };
                                                                        }}
                                                                    />
                                                                    <div className='col-md-3 col-sm-6 mt-md-4'>

                                                                        <button className="btn btn-sm btn-success" onClick={handleAddLinks}>
                                                                            <i className="bi bi-plus-lg" /> Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {images?.length > 0 ?
                                                            <div className='pt-3 '>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-striped">
                                                                        <thead>
                                                                            <tr className='light-bg'>
                                                                                <th className='' >Action</th>
                                                                                <th >Image / Video </th>
                                                                                <th >Image/Video Name</th>
                                                                                <th >Make Primary</th>
                                                                                <th >Sequence</th>
                                                                                <th >Type</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>


                                                                            {images.map((image, index) => {
                                                                                return <tr key={index} className=''>
                                                                                    <td >
                                                                                        <i className="fa fa-trash-can text-danger me-2" style={{ cursor: 'pointer' }} onClick={() => handleDeleteImage(index)} /></td>
                                                                                    <td className='p-0' style={{ cursor: 'pointer' }}>
                                                                                        <img
                                                                                            className="border border-success img-thumbnail"
                                                                                            key={index}
                                                                                            src={image.type === 'Video' ? "assets/img/video_show_on_table.png" : `data:image/${image.imgName};base64,${image.img}`}
                                                                                            onClick={() => image.type !== "Video" && handleShowImageInModal(`data:image/${image.imgName};base64,${image.img}`)}
                                                                                            alt={image.imgExt}
                                                                                            style={image.type === 'Video' ? { width: '80px', height: '40px', padding: '1px' } : { width: '50px', height: '50px' }} />
                                                                                    </td>
                                                                                    <td >{image.imgName}</td>
                                                                                    <td>
                                                                                        {/*  <input type='radio' name={`PrimaryImage-${index}`} disabled={image.type === 'Video'} checked={image.isPrimaryImage} onChange={(e) => HandleMakePrimary(index, e.target.checked)} />*/}
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="PrimaryImage"
                                                                                            disabled={image.type === 'Video'}
                                                                                            checked={image.isPrimaryImage}
                                                                                            onChange={() => HandleMakePrimary(index)}
                                                                                        />
                                                                                    </td>

                                                                                    <td className='m-0 p-0' style={{ maxWidth: '0rem' }} >
                                                                                        <InputBox divclassname='' customclass='pb-2' value={image.sequence} onChange={(e) => {
                                                                                            const data = [...images];
                                                                                            data[index] = { ...data[index], sequence: e.target.value }
                                                                                            setImages(data)
                                                                                            setFormData({ ...formData, BulkImageList: data });
                                                                                        }} /></td>
                                                                                    <td >{image.type}</td>
                                                                                </tr>
                                                                            })}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            : <></>}


                                                    </div>
                                                    : <></>}
                                            </div>
                                            <div className="Product_container mb-3">
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductSEO: !isCollapsed.ProductSEO }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.ProductSEO ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Product SEO </span>

                                                    </h6>
                                                    <div >
                                                        <i className={`fa   ${!isCollapsed.ProductSEO ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>{isCollapsed.ProductSEO ?
                                                    <div className='row mt-2 mx-1'>
                                                        <InputBox
                                                            id="txtSEOFriendlyPageName"
                                                            label='SEO Friendly Page Name'
                                                            placeholder='SEO Friendly Page Name'
                                                            required
                                                            maxLength='500'
                                                            value={formData.SearchEnginePageName}
                                                            onChange={(e) => setFormData({ ...formData, SearchEnginePageName: e.target.value })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("") };
                                                            }}
                                                        />
                                                        <InputBox
                                                            id="txtMetaTitle"
                                                            label='Meta Title'
                                                            placeholder='Meta Title'
                                                            maxLength='100'
                                                            value={formData.MetaTitle}
                                                            onChange={(e) => setFormData({ ...formData, MetaTitle: (e.target.value) })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtMetaKeywords") };
                                                            }}
                                                        />
                                                        <InputBox
                                                            id="txtMetaKeywords"
                                                            label='Meta Keywords'
                                                            placeholder='Meta Keywords'
                                                            maxLength='100'
                                                            value={formData.MetaKeywords}
                                                            onChange={(e) => setFormData({ ...formData, MetaKeywords: (e.target.value) })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtMetaDescription") };
                                                            }}
                                                        />
                                                        <div className="col-lg-12 ">
                                                            <div className="fields">
                                                                <div className="d-flex justify-content-between">  <label className="form-label">Meta Description</label> <span>{remainingChars}/500</span></div>
                                                                <textarea
                                                                    id="txtMetaDescription"
                                                                    className="col-12"
                                                                    name='MetaDescription'
                                                                    rows={3}
                                                                    maxLength={500}
                                                                    value={formData.MetaDescription}
                                                                    onChange={(e) => {
                                                                        setFormData({ ...formData, MetaDescription: e.target.value })

                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("btnsave") };
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <></>}
                                            </div>
                                            <div className="Product_container mb-3" >
                                                <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, RelatedProducts: !isCollapsed.RelatedProducts }) }}>
                                                    <h6 className={` m-0   ${isCollapsed.RelatedProducts ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                        <span className=''>Related Products</span>

                                                    </h6>
                                                    <div  >
                                                        <i className={`fa   ${!isCollapsed.RelatedProducts ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                                </div>{isCollapsed.RelatedProducts ?
                                                    <div className='row mt-2 mx-1 mb-3'>


                                                        <MultiSelectInputBox
                                                            //required
                                                            label='Related Products'
                                                            placeholder='--Select Related Products--'
                                                            id='RelatedProducts'
                                                            divclassname='col-md-12 mb-3'
                                                            options={RequiredProductsOption}
                                                            value={formData.RelatedProducts}
                                                            isOptionDisabled={() => formData.RelatedProducts.length >= 20}

                                                            onChange={(e) => {
                                                                setFormData({ ...formData, RelatedProducts: e })
                                                            }}
                                                        />

                                                    </div>

                                                    : <></>}
                                            </div>
                                            <div className="col-lg-12 mt-4 z-0">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnsave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                    {productUrl !== "" ? (
                                                        <button className="button Prev-btn" onClick={handlePreviewProduct}>
                                                            <i className="fa-solid fa-boxes-packing" /> Preview Product
                                                        </button>
                                                    ) : <></>}

                                                    <button className="button reset-btn" onClick={() => { handleClear() }}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='ProductMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getProductListingShow()
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        ProductPreview={ProductPreview}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Product Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow: 'auto', width:"100%" }}>
                        <img src={modalImg} alt='ProductImageinModal' style={{ width: "-webkit-fill-available" }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default ProductListing






